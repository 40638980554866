import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar/Navbar';
import Hero from './components/Hero/Hero';
import About from './components/About/About';
import Slider from './components/Slider/Slider';
import Token from './components/Token/Token';

function App() {
  return (
    <div className='max-w-[100vw]'>
      <div className='hidden md:block'>
        <Navbar />
      </div>
      <Hero />
      <Slider />
      <About />
      <Slider />
      <Token />
      <Slider />

    </div>
  );
}

export default App;
