import React from 'react'
import Lottie from 'react-lottie'
import * as animationData from '../../Assets/coin.json'
import * as fire from '../../Assets/fire.json'

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};
const fireOptions = {
    loop: true,
    autoplay: true,
    animationData: fire,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};
const Token = () => {
    return (
        <div className='bg_dog'>
            <section class="text-gray-600 body-font">
                <div class="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
                    <Lottie options={defaultOptions}
                        // height={400}
                        width={360}
                    />
                    {/* <img class="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600" /> */}
                    <div class="text-center  w-full">
                        <h1 class="title-font sm:text-4xl text-3xl md:text-[62px] mb-4 font-medium text-gray-900">$Podenomics</h1>
                        <div>
                            <div className=' gap-4'>
                                <Lottie options={fireOptions}
                                    // height={400}
                                    width={100}
                                />
                                <p class="mb-8 leading-relaxed text-md md:text-[50px] text-[#A7603E] text-center">LP Burned</p>

                            </div>
                            <div className=' gap-4'>
                                
                                <p class="mb-8 leading-relaxed text-md md:text-[50px] text-[#A7603E] text-center">100 Bill SUpply</p>

                            </div>
                            <div className=' gap-4'>
                               
                                <p class="mb-8 leading-relaxed text-md md:text-[50px] text-[#A7603E] text-center">No Tax</p>

                            </div>

                        </div>
                        <div class="flex justify-center gap-4">
                            <button class=" inline-flex items-center bg-gray-100 border-4 border-black py-6 px-6 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0 text-[30px] md:text-[40px] ">Buy Now
                                
                            </button>
                            
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Token