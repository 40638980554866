import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from '../../Assets/camera.json'


const Hero = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <div className='bg_dog'>
            <section class="text-gray-600 body-font">
                <div class="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
                    <div className='flex justify-center'>
                        {/* <Lottie options={defaultOptions}
                            height={400}
                            // width={400}
                        /> */}
                        <img className='w-[300px] sm:w-[400px] md:w-[50%]' src={require('../../Assets/base.png')} />
                    </div>
                    {/* <img class="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded" alt="hero" src="https://dummyimage.com/720x600"/> */}
                    <div class="text-center lg:w-2/3 w-full">
                        <h1 class="title-font  mb-4 font-medium text-gray-900 text-[60px]">$POD</h1>
                        <p class="mb-8 leading-relaxed text-[28px] text-[#A7603E]">Proof of Dog is the ultimate meme coin for dog lovers and crypto enthusiasts. Fueled by barks and wagging tails, it's a fun ride through the world of decentralized woofonomics!</p>
                        <div class="flex justify-center gap-4">
                            <button class=" inline-flex items-center bg-gray-100 border-4 border-black py-6 px-6 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0 text-[30px] md:text-[40px] ">Twitter
                                
                            </button>
                            <button class="inline-flex items-center bg-gray-100 border-4 border-black py-6 px-6 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0 text-[30px] md:text-[40px]">Telegram
                                
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Hero