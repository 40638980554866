import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from '../../Assets/camera.json'
const About = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <div className='bg_dog'>
            <section class="text-gray-600 body-font overflow-hidden">
                <div class="container px-5 py-24 mx-auto">
                    <div class="lg:w-4/5 mx-auto flex flex-wrap">
                        <Lottie options={defaultOptions}
                            class="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded"
                        // height={400}
                        // width={400}
                        />
                        {/* <img alt="ecommerce" class="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded" src="https://dummyimage.com/400x400" /> */}
                        <div class=" w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
                            <h1 class="text-gray-900 text-3xl text-center md:text-[60px] mb-6 title-font font-medium">About $POD</h1>

                            <p class="leading-relaxed text-[30px] text-[#A7603E]">At Proof of Dog, we believe in a tax-free, no-nonsense approach to meme coins. Why complicate things when all you need is a love for dogs? Everyone has a dog (or wishes they did), so why not show off your furry friend and be part of the most pawsome crypto community? Proof of Dog is all about sharing the joy of our pets—no taxes, no drama, just pure doggo fun!</p>


                        </div>
                       
                    </div>
                </div>
            </section>
        </div>
    )
}

export default About